import React from 'react';

import './Login.css'

import { Form, Input, Button, notification } from 'antd';

import { apiLogin } from './api'

import { withRouter } from 'react-router-dom';

class Login extends React.Component {
  constructor(props) {
    super(props);
  }

  onFinish = async (values) => {
    const repData = await apiLogin(values);
    if (repData && repData.code == 0) {
      localStorage.setItem("token", repData.data);
      this.props.history.replace('/studentList');
    } else {
      notification['error']({
        message: '登录失败',
        description:
          repData.msg,
      });
    }
  };


  render () {
    return (
      <div className='div_container'>

        <div className='div_form'>
          <div className='div_title'>汉川金益高中报名后台系统</div>
          <Form
            style={{ marginTop: '20px' }}
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={this.onFinish}

            autoComplete="off"
          >

            <Form.Item
              label="用户名"
              name="username"
              rules={[
                {
                  required: true,
                  message: '请输入用户名',
                },
              ]}
            >
              <Input className='input' />
            </Form.Item>

            <Form.Item
              label="密码"
              name="password"
              rules={[
                {
                  required: true,
                  message: '请输入密码',
                },
              ]}
            >
              <Input.Password className='input' />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Button className='submit' type="primary" htmlType="submit">
                登录
              </Button>
            </Form.Item>
          </Form>
        </div>


      </div>
    );
  }
}

export default withRouter(Login);