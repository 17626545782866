import logo from './logo.svg';
import './App.css';
import ReactDOM from "react-dom";
import {
  HashRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Login from './Login';
import AddStudent from './AddStudent';
import StudentList from './StudentList';


const eleTitle = (ele, title) => {
  document.title = title;
  return ele;
}

function App () {
  return (
    <Router>
      <Switch>
        <Route path="/login" render={() => eleTitle(<Login />, '报名系统登录')}>
        </Route>
        <Route path="/addStudent" render={() => eleTitle(<AddStudent />, '汉川金益高中报名入口')}>
        </Route>
        <Route path="/studentList" render={() => eleTitle(<StudentList />, '学生列表')}>
        </Route>
        <Route path="/" render={() => eleTitle(<Login />, '报名系统登录')}>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
