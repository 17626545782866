import React from 'react';
import './AddStudent.css'
import { Form, Input, Button, Radio, Select, message, Modal, notification } from 'antd';
import { apiAddStudents } from './api'
import TextArea from 'antd/lib/input/TextArea';

export default class AddStudent extends React.Component {
  createLable = (val) => {
    return <span style={{ fontSize: '16px' }}>{val}</span>
  }

  onFinish = async (values) => {
    const repData = await apiAddStudents(values);
    if (repData && repData.code === 0) {
      Modal.success({
        content: '你的报名申请已提交',
        okText: '确定',
      });
    } else {
      notification['error']({
        message: '报名失败',
        description:
          repData.msg,
      });
    }
  }

  onFinishFailed = () => {
    message.warning('请填写完整信息');
  }

  render () {
    return (
      <div className='div_add_stu_container'>
        <h1>汉川金益高中报名入口</h1>
        <Form
          layout="vertical"
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
          autoComplete="off"
        >
          <Form.Item label={this.createLable('学生姓名')} name="name" rules={[
            {
              required: true,
              message: '请输入学生姓名',
            },
          ]} >
            <Input style={{ fontSize: '16px' }} placeholder="请输入学生姓名" />
          </Form.Item>
          <Form.Item label={this.createLable('性别')} name="gender" rules={[
            {
              required: true,
              message: '请选择学生性别',
            },
          ]} >
            <Radio.Group>
              <Radio value="1">男</Radio>
              <Radio value="2">女</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item label={this.createLable('籍贯')} name="nation" rules={[
            {
              required: true,
              message: '请输入籍贯',
            },
          ]} >
            <Input style={{ fontSize: '16px' }} placeholder="请输入籍贯" />
          </Form.Item>

          <Form.Item label={this.createLable('身份证号')} name="idcard" rules={[
            {
              required: true,
              message: '请输入身份证号',
            },
          ]} >
            <Input style={{ fontSize: '16px' }} placeholder="请输入身份证号" />
          </Form.Item>

          <Form.Item label={this.createLable('原就读学校')} name="oldSchool" rules={[
            {
              required: true,
              message: '请输入原就读学校',
            },
          ]} >
            <Input style={{ fontSize: '16px' }} placeholder="请输入原就读学校" />
          </Form.Item>

          <Form.Item label={this.createLable('原就读年级')} name="oldGrade" rules={[
            {
              required: true,
              message: '请输入原就读年级',
            },
          ]} >
            <Input style={{ fontSize: '16px' }} placeholder="请输入原就读年级" />
          </Form.Item>

          <Form.Item label={this.createLable('申请就读年级')} name="nowGrade" rules={[
            {
              required: true,
              message: '请选择申请就读年级',
            },
          ]} >
            <Select style={{ fontSize: '16px' }} placeholder="请选择申请就读年级">
              <Select.Option value="GAOYI">高一</Select.Option>
              <Select.Option value="GAOER">高二</Select.Option>
              <Select.Option value="GAOSAN">高三</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item label={this.createLable('户籍所在地')} name="registeredAddress" rules={[
            {
              required: true,
              message: '请输入户籍所在地',
            },
          ]} >
            <Input style={{ fontSize: '16px' }} placeholder="请输入户口本地址" />
          </Form.Item>

          <Form.Item label={this.createLable('现居住地址')} name="address" rules={[
            {
              required: true,
              message: '请输入现居住地址',
            },
          ]} >
            <Input style={{ fontSize: '16px' }} placeholder="请输入现居住地址" />
          </Form.Item>

          <Form.Item label={this.createLable('家长姓名1')} name="parent1" rules={[
            {
              required: true,
              message: '请输入家长姓名',
            },
          ]} >
            <Input style={{ fontSize: '16px' }} placeholder="请输入家长姓名" />
          </Form.Item>

          <Form.Item label={this.createLable('联系方式1')} name="parentPhone1" rules={[
            {
              required: true,
              message: '请输入联系方式',
            },
          ]} >
            <Input type='number' style={{ fontSize: '16px' }} placeholder="请输入联系方式" />
          </Form.Item>

          <Form.Item label={this.createLable('(选填)家长姓名2')} name="parent2"  >
            <Input style={{ fontSize: '16px' }} placeholder="请输入家长姓名" />
          </Form.Item>
          <Form.Item label={this.createLable('(选填)联系方式2')} name="parentPhone2"  >
            <Input type='number' style={{ fontSize: '16px' }} placeholder="请输入联系方式" />
          </Form.Item>

          <Form.Item label={this.createLable('(选填)荣誉评价')} name="evaluate"  >
            <TextArea style={{ fontSize: '16px' }} placeholder="请输入荣誉评价" />
          </Form.Item>

          <Form.Item label={this.createLable('(选填)备注')} name="remarks"  >
            <TextArea style={{ fontSize: '16px' }} placeholder="请输入备注" />
          </Form.Item>

          <Form.Item>
            <Button className='div_add_submit' type="primary" htmlType="submit">提交报名</Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}
