import http from './http';



/**
 * 获取首页列表
 */
export function apiLogin (params) {
	return http("get", '/v1/admin/login', params);
}

export function apiStudents (params) {
	return http("get", '/v1/student/list', params);
}

export function apiAddStudents (data) {
	return http("post", '/v1/student/create', data);
}