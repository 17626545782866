import React from 'react';

import './StudentList.css'
import { Form, Input, Button, Table, Tag, Drawer } from 'antd';
import moment from 'moment';

import { apiStudents } from './api'


export default class StudentList extends React.Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: '序号',
        render: (text, record, index) => {
          return index + 1;
        },
      },
      {
        title: '姓名',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '性别',
        render: (text, record, index) => {
          if (record.gender == 1) return <Tag color="blue">男</Tag>;
          if (record.gender == 2) return <Tag color="magenta">女</Tag>;
          return '其他';
        },
      },
      {
        title: '籍贯',
        dataIndex: 'nation',
        key: 'nation',
      },
      {
        title: '身份证',
        dataIndex: 'idcard',
        key: 'idcard',
      },
      {
        title: '原就读学校--年级',
        dataIndex: 'oldSchool',
        key: '原就读学校',
        render: (text, record, index) => {
          return record.oldSchool + "--" + record.oldGrade;
        },
      },
      {
        title: '申请就读年级',
        render: (text, record, index) => {
          switch (record.nowGrade) {
            case 'GAOYI':
              return <Tag color="orange">高一</Tag>;
            case 'GAOER':
              return <Tag color="blue">高二</Tag>;
            case 'GAOSAN':
              return <Tag color="purple">高三</Tag>;
          }
        },
      },
      {
        title: '家长1',
        render: (text, record, index) => {
          return record.parent1 + " : " + record.parentPhone1;
        },
      },
      {
        title: '填报时间',
        render: (text, record, index) => {
          return moment(record.createTime).format('YYYY-MM-DD HH:MM');
        },
      },
      {
        title: '操作',
        render: (text, record, index) => {
          return <a onClick={() => {
            this.showInfo(record)
          }} style={{ padding: '10px' }}>详情</a>
        },
      },
    ]
    this.state = {
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      searchValues: {},
      visible: false,
      studentInfo: {}
    }

    this.loadStudents();
  }

  showInfo = (record) => {
    this.setState({
      studentInfo: record,
      visible: true
    })
  }

  onClose = () => {
    this.setState({
      visible: false
    })
  }

  loadStudents = async () => {
    this.setState({ loading: true });
    let { pagination, searchValues } = this.state;
    let params = {
      pageSize: pagination.pageSize,
      pageNum: pagination.current, //
    };
    const res = await apiStudents({ ...params, ...searchValues });
    if (res && res.code == 0) {
      pagination.total = res.data.total;
      this.setState({
        pagination,
        students: [...res.data.list],
      });
    }
  };

  onPageChange = (current, pageSize) => {
    this.setState({ pagination: { ...this.state.pagination, current, pageSize } }, () =>
      this.loadStudents(),
    );
  };

  onFinish = (values) => {
    let { name, idCard } = values;
    name = name ? name : '';
    idCard = idCard ? idCard : '';
    this.setState({
      searchValues: {
        name,
        idCard
      }
    }, () => {
      this.loadStudents();
    })
  }

  reset = () => {
    this.setState({
      searchValues: {
        name: '',
        idCard: ''
      }
    }, () => {
      this.loadStudents();
    })
  }


  render () {
    const { pagination, students, visible, studentInfo } = this.state;
    return (
      <div className='div_stu_container'>
        <Form
          name="advanced_search"
          className="ant-advanced-search-form"
          layout='horizontal'
          onFinish={this.onFinish}
        >
          <Form.Item
            label="姓名"
            name="name"
            className='ant-advanced-search-form-item'

          >
            <Input className='input' />
          </Form.Item>
          <Form.Item
            label="身份证号"
            name="idCard"
            className='ant-advanced-search-form-item'

          >
            <Input />
          </Form.Item>
          <Form.Item
            className='ant-advanced-search-form-item'
          >
            <Button type="primary" className='student_btn' htmlType="submit">
              搜索
            </Button>
          </Form.Item>
          <Form.Item
            className='ant-advanced-search-form-item'
          >
            <Button onClick={this.reset} className='student_btn' htmlType="reset">
              重置
            </Button>
          </Form.Item>
        </Form>

        <div className='div_table'>
          <Table
            dataSource={students}
            columns={this.columns}
            bordered
            pagination={{
              pageSize: pagination.pageSize,
              current: pagination.current,
              total: pagination.total,
              onChange: this.onPageChange,
              size: 'small',
              showTotal: (total) => `共${total}条`,
            }}>

          </Table>
        </div>

        {visible && <Drawer width={'35%'} title={studentInfo.name + '详情'} placement="right" onClose={this.onClose} visible={visible}>
          <div className='div_student_info'>
            <span style={{ fontSize: '14px', marginTop: '5px', color: '#666666' }}>姓名</span>
            <span style={{ fontSize: '17px', marginTop: '5px', color: '#333333' }}>{studentInfo.name}</span>
          </div>
          <div className='div_student_info'>
            <span style={{ fontSize: '14px', marginTop: '5px', color: '#666666' }}>性别</span>
            <span style={{ fontSize: '17px', marginTop: '5px', color: '#333333' }}>{studentInfo.gender == 1 ? '男' : '女'}</span>
          </div>
          <div className='div_student_info'>
            <span style={{ fontSize: '14px', marginTop: '5px', color: '#666666' }}>籍贯</span>
            <span style={{ fontSize: '17px', marginTop: '5px', color: '#333333' }}>{studentInfo.nation}</span>
          </div>
          <div className='div_student_info'>
            <span style={{ fontSize: '14px', marginTop: '5px', color: '#666666' }}>身份证号</span>
            <span style={{ fontSize: '17px', marginTop: '5px', color: '#333333' }}>{studentInfo.idcard}</span>
          </div>
          <div className='div_student_info'>
            <span style={{ fontSize: '14px', marginTop: '5px', color: '#666666' }}>原就读学校</span>
            <span style={{ fontSize: '17px', marginTop: '5px', color: '#333333' }}>{studentInfo.oldSchool}</span>
          </div>
          <div className='div_student_info'>
            <span style={{ fontSize: '14px', marginTop: '5px', color: '#666666' }}>原就读年级</span>
            <span style={{ fontSize: '17px', marginTop: '5px', color: '#333333' }}>{studentInfo.oldGrade}</span>
          </div>
          <div className='div_student_info'>
            <span style={{ fontSize: '14px', marginTop: '5px', color: '#666666' }}>申请就读年级</span>
            <span style={{ fontSize: '17px', marginTop: '5px', color: '#333333' }}>{studentInfo.nowGrade == 'GAOYI' ? '高一' : ''}{studentInfo.nowGrade == 'GAOER' ? '高二' : ''}{studentInfo.nowGrade == 'GAOSAN' ? '高三' : ''}</span>
          </div>
          <div className='div_student_info'>
            <span style={{ fontSize: '14px', marginTop: '5px', color: '#666666' }}>户籍所在地</span>
            <span style={{ fontSize: '17px', marginTop: '5px', color: '#333333' }}>{studentInfo.registeredAddress}</span>
          </div>
          <div className='div_student_info'>
            <span style={{ fontSize: '14px', marginTop: '5px', color: '#666666' }}>家长姓名1</span>
            <span style={{ fontSize: '17px', marginTop: '5px', color: '#333333' }}>{studentInfo.parent1}</span>
          </div>
          <div className='div_student_info'>
            <span style={{ fontSize: '14px', marginTop: '5px', color: '#666666' }}>家长电话1</span>
            <span style={{ fontSize: '17px', marginTop: '5px', color: '#333333' }}>{studentInfo.parentPhone1}</span>
          </div>
          <div className='div_student_info'>
            <span style={{ fontSize: '14px', marginTop: '5px', color: '#666666' }}>家长姓名2</span>
            <span style={{ fontSize: '17px', marginTop: '5px', color: '#333333' }}>{studentInfo.parent2}</span>
          </div>
          <div className='div_student_info'>
            <span style={{ fontSize: '14px', marginTop: '5px', color: '#666666' }}>家长电话2</span>
            <span style={{ fontSize: '17px', marginTop: '5px', color: '#333333' }}>{studentInfo.parentPhone2}</span>
          </div>
          <div className='div_student_info'>
            <span style={{ fontSize: '14px', marginTop: '5px', color: '#666666' }}>报名日期</span>
            <span style={{ fontSize: '17px', marginTop: '5px', color: '#333333' }}>{moment(studentInfo.createTime).format('YYYY-MM-DD HH:MM')}</span>
          </div>
        </Drawer>}
      </div>
    );
  }
}

